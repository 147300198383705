import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import styles from './VideoCommentsTabStyle.module.scss';
import './VideoCommentsTabStyle.scss';

import DetailsHeader from '../DetailsTabHeader/DetailsHeader';
import CardVideo from './CardVideo/CardVideo';

const { TabPane } = Tabs;

const VideoComments = ({ card, cardId, fetchCardDetails }) => {
	const [tabsIncluded, setTabsIncluded] = useState(false);
	const [activeTabKey, setActiveTabKey] = useState("repurposeDoneVideo"); // Default to "Done Video"
	const [doneData, setDoneData] = useState({});
	const [repurposeData, setRepurposeData] = useState({});

	const isTabsIncluded = (card) => {
		if (card?.repurpose_done_videos.length > 0) {
			setTabsIncluded(true);
		} else {
			setTabsIncluded(false);
			setActiveTabKey("repurposeDoneVideo"); // Default to "Done Video"
		}
	};

	useEffect(() => {
		isTabsIncluded(card);
	}, [card]);

	// useEffect(() => {
	// 	if (activeTabKey === "doneVideo") {

	// 	}
	// 	if (activeTabKey === "repurposeDoneVideo") {
	// 		setRepurposeData(card);
	// 	}
	// }, [activeTabKey]);

	useEffect(() => {
		setRepurposeData(card);
		setDoneData({
			...card,
			repurpose_video_versions: [],
			repurpose_done_videos: [],
			repurpose_done_link: ""
		});
	}, [card]);

	return (
		<div className={styles.container}>
			<DetailsHeader
				fetchCardDetails={fetchCardDetails}
				card={card}
			/>
			{tabsIncluded ? (
				<Tabs className='cardVideoType' activeKey={activeTabKey} onChange={(key) => setActiveTabKey(key)}>
					<TabPane tab="Done Videos" key="doneVideo">
						<CardVideo
							card={doneData}
							cardId={cardId}
							allowNewChat={false}
						/>
					</TabPane>
					<TabPane tab="Repurpose Videos" key="repurposeDoneVideo">
						<CardVideo
							card={repurposeData}
							cardId={cardId}
							allowNewChat={true}
						/>
					</TabPane>
				</Tabs>
			) : (
				<CardVideo
					card={card}
					cardId={cardId}
					allowNewChat={true}
				/>
			)}
		</div>
	);
};

export default VideoComments;
