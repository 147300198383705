import React, { useState } from "react";
import { Row, TextInput, Button, Container, Col } from "react-materialize";
import { axiosClient } from "../../api/httpClient";
import { errorToast, successToast } from "../../api/Util";

const ChangePassword = ({ loggedInUser, client_id }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = () => {
    if (password !== confirmPassword) {
      errorToast("Passwords do not match!");
      return;
    }

    axiosClient.put("/api/change-password", { client_id, password })
      .then(response => {
        if (response.data.status) {
          successToast(response.data.message);
        } else {
          errorToast(response.data.message || "Failed to change password");
        }
      })
      .catch(error => {
        console.error("Error changing password:", error);
        errorToast("An error occurred while changing the password");
      });
  };

  const isDisabled = password === "" || confirmPassword === "" || password !== confirmPassword;

  return (
    <Container id="customer-tabs-container">
      <Row>
        <Col s={12} m={6}>
          <TextInput
            s={12}
            type="password"
            label="New Password *"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Col>
        <Col s={12} m={6}>
          <TextInput
            s={12}
            type="password"
            label="Confirm Password *"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </Col>
      </Row>
      <Button type="submit" onClick={handleSubmit} disabled={isDisabled}>
        Change Password
      </Button>
    </Container>
  );
};

export default ChangePassword;
